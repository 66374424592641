@import "variables";
@import "mixins";
@import "fonts";
@import "hamburgers";

body {
	margin: 0;
	padding: 0;
	width: 100%;
}

html[lang="ar"] {
	direction: rtl;

	* {
		text-align: right;
	}
}

* {
	font-family: sans-serif;
	box-sizing: border-box;
	line-height: 1;
	font-size: 1vw;
}


.grecaptcha-badge {
	display: none !important;
}

#Main {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.content-container {
		flex-grow: 1;
		position: relative;
	}
}

.container {
	@media screen and (max-width: $mobile) and (orientation: portrait) {
		// padding-left: 5vw !important;
		// padding-right: 5vw !important;
		width: 95% !important;
	}
}

.see-more {
	display: inline-block;
	margin: 1vw auto;
	color: $darkgrey !important;
	text-decoration: none;
	font-size: 1vw;
	padding-left: 1.25rem;
}

.btn {
	cursor: pointer;
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 3% 0;

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		margin: 0 1.5%;
	}

	li {
		display: table;
		background-color: #fff;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
		margin: 0 0.5%;
		text-align: center;
		font-family: "SemiBold";

		transition: all 0.1s ease-in-out;
		cursor: pointer;

		@media screen and (max-width: $mobile) and (orientation: portrait) {
			margin: 0 1.5%;
		}

		a {
			display: table-cell;
			vertical-align: middle;
			width: 2vw;
			height: 2vw;
			font-size: 0.8vw;
			color: #000;
			text-decoration: none !important;
			outline: none !important;
			text-align: center;

			@media screen and (max-width: $mobile) and (orientation: portrait) {
				width: 7vw;
				height: 7vw;
				font-size: 3vw;
			}
		}

		&.active,
		&:hover {
			background-color: $red;

			a {
				color: #fff;
			}
		}

		&.disabled {
			background-color: lightgrey;
			color: #fff;
			pointer-events: none;
		}
	}
}

.closeButton {
	@media screen and (max-width: $mobile) and (orientation: portrait) {
		top: 1.5vw;
		right: 1.5vw;

		svg {
			width: 4vw;
			height: 4vw;
		}
	}
}

.toastCont {
	color: #2d2d2d !important;
	border-left: 0.7vw solid #e61a23;
	padding-left: 1.5vw !important;

	p {
		margin: 0;
		line-height: 1.5;

		&:first-child {
			font-size: 0.7vw;
			font-family: SemiBold;
		}

		&:last-child {
			font-size: 0.8vw;
		}
	}

	@media screen and (max-width: $mobile) {
		max-width: 90%;
		margin: 0 auto;
		top: 15vw;
		border-width: 2vw;
		padding-left: 3vw !important;
		margin-bottom: 2vw !important;

		p {
			&:first-child {
				font-size: 3.2vw;
				font-family: SemiBold;
			}

			&:last-child {
				font-size: 3vw;
			}
		}
	}
}

.toastProgress {
	background: rgba($red, 0.4) !important;
}

@media (min-width: 1200px) and (max-width: 1400px) {
	.container {
		max-width: 960px !important;
	}
}

.slick-list {
	// border: 4px solid #420f0f;
}

.slick-dots {
	bottom: -2vmax;
	text-align: left;

	>li {
		width: 1.3vmin;
		height: 1.3vmin;

		button {
			background: #fff;
			border-radius: 50%;
			width: 100%;
			height: 100%;
			padding: 0;
			background: lightgray;

			&::before {
				content: "";
				// width: 65%;
				// height: 65%;
				line-height: 1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
			}
		}

		&.slick-active {
			width: 3.3vmin;

			button {
				background: #e61a23;
				border-radius: 10px;
			}
		}
	}

	@media screen and (max-width: $mobile) and (orientation: portrait) {
		bottom: -4vmax;

		>li {
			width: 3vmin;
			height: 3vmin;

			&.slick-active {
				width: 9vmin;
			}
		}
	}
}